<script setup lang="ts">
import CMLogo from '@/assets/icons/cm-logo.svg?skipsvgo';
import Joi from 'joi';
import PasswordInput from '~/components/auth/PasswordInput.vue';
import { EVENTS, PAGES } from '~/constants/events';
import { TN_ERROR_CODES } from '@tn/shared';
import { useLoginMutation } from '~/features/auth';

const route = useRoute();
const loginMutation = useLoginMutation();
const { verifySSOToken, onClickLoginCarbManager, ssoLoading, ssoError } =
  useCarbManagerSSO();

definePageMeta({
  layout: false,
  eventPageName: PAGES.LOGIN,
  name: 'login',
  colorMode: 'light',
});

const isSubmitting = ref(false);
const errorMessage = ref('');

const schema = Joi.object({
  email: Joi.string()
    .email({ tlds: { allow: false } })
    .required()
    .messages({
      'string.email': 'Please enter a valid email address',
    }),
  password: Joi.string().required(),
}).messages({
  'any.required': 'This field is required',
  'string.empty': 'This field is required',
});

const state = reactive({
  email: '',
  password: '',
});

const { $trackEvent, $sentry } = useNuxtApp();

onMounted(async () => {
  $trackEvent(EVENTS.LOGIN_VIEWED, {
    login_type: 'password',
  });

  await verifySSOToken().then(async () => {
    await navigateTo({ name: 'home' });
  });
});

const resetPasswordClicked = () => {
  $trackEvent(EVENTS.CANT_LOGIN_BUTTON_CLICKED);
};

const onClickOneTimeLink = () => {
  $trackEvent(EVENTS.LOGIN_ONE_TIME_LINK_CLICKED);
};

const login = async () => {
  errorMessage.value = '';
  isSubmitting.value = true;

  try {
    const user = await emailLogin(state.email, state.password);

    $trackEvent(EVENTS.LOGIN_SUBMITTED, {
      outcome: 'success',
      login_type: 'password',
    });

    const { redirect } = await loginMutation.mutateAsync({
      user,
      loginType: 'password',
    });

    await navigateTo(redirect);
  } catch (error) {
    $sentry.captureException(error);
    const typedError = error as { data?: { code?: string } };
    const errorCode = typedError?.data?.code;
    let outcomeType: string;
    isSubmitting.value = false;
    ssoError.value = null;

    switch (errorCode) {
      case TN_ERROR_CODES.MISSING_EMAIL_PASSWORD:
        outcomeType = 'password_error';
        errorMessage.value = 'Invalid password';
        break;
      case TN_ERROR_CODES.INVALID_EMAIL:
        outcomeType = 'email_error';
        errorMessage.value = 'Invalid email';
        break;
      case TN_ERROR_CODES.INVALID_LOGIN_CREDENTIALS:
        outcomeType = 'invalid_credentials';
        errorMessage.value = 'Invalid email or password';
        break;
      default:
        outcomeType = 'login_failed';
        errorMessage.value = 'Login failed';
    }

    $trackEvent(EVENTS.LOGIN_SUBMITTED, {
      login_type: 'password',
      outcome: outcomeType,
    });

    console.error(error);
  }
};
</script>

<template>
  <NuxtLayout name="onboarding-base">
    <template #header>
      <AuthHeader />
    </template>

    <template #content>
      <template v-if="ssoLoading && route.query.sso_token">
        <div class="flex w-full flex-col md:w-[400px]">
          <div class="mt-10">
            <UProgress animation="carousel" size="2xl" color="green" />
            <h3 class="font-roca py-4 text-center text-lg font-medium">
              Logging you in...
            </h3>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="flex w-full flex-col md:w-[430px]">
          <UAlert
            v-if="ssoError"
            icon="i-heroicons-exclamation-triangle"
            color="rose"
            class="mt-4"
            variant="soft"
            title="Whoops!"
            :description="ssoError"
          />

          <h2
            class="font-roca py-6 text-center text-lg font-semibold sm:text-3xl"
          >
            Welcome Back
          </h2>

          <UForm
            :schema="schema"
            :state="state"
            :validate-on="['submit']"
            method="post"
            class="space-y-4"
            @submit="login"
          >
            <UFormGroup v-slot="{ error }" name="email">
              <TnInput
                v-model="state.email"
                label="Email Address"
                placeholder="Email Address"
                required
                :error="!!error"
                input-type="email"
                :show-asterisk="false"
              />
            </UFormGroup>
            <UFormGroup v-slot="{ error }" name="password">
              <PasswordInput v-model="state.password" :error="!!error" />
              <div v-if="errorMessage" class="pt-2 text-xs text-red-500">
                {{ errorMessage }}
              </div>
            </UFormGroup>
            <UButton size="sm" block type="submit" :loading="isSubmitting"
              >Login</UButton
            >
          </UForm>

          <div class="my-4">
            <UDivider
              label="OR"
              :ui="{
                container: {
                  base: 'text-neutral-400',
                },
                label: 'text-base',
              }"
            />
          </div>

          <UButton
            size="sm"
            block
            color="secondary"
            @click="onClickLoginCarbManager"
          >
            <span class="inline-block h-6 w-6">
              <CMLogo />
            </span>
            <span> Login with Carb Manager </span>
          </UButton>

          <UButton
            class="mt-3"
            size="sm"
            block
            :to="{ name: 'login-magic' }"
            color="secondary"
            @click="onClickOneTimeLink"
            >Sign in with one-time link</UButton
          >

          <NuxtLink
            :to="{ name: 'member-reset-password' }"
            class="font-roca mb-2 mt-4 text-center text-base font-semibold text-blue-600 hover:underline"
            @click="resetPasswordClicked"
          >
            Can't log in?
          </NuxtLink>
        </div>
      </template>
    </template>
    <template #footer>
      <AuthFooter :can-show-privacy-policy="false" />
    </template>
  </NuxtLayout>
</template>
